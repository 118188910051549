<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center w-100 mb-1">
      <h3>
        {{ listRoute.find((item)=>item.CurrentRoute==$route.name).title }}
      </h3>
    </div>
    <hr />
    <b-row v-if="loading">
      <b-col cols="3" v-for="n in 8" :key="n">
        <b-skeleton width="100%" height="120px" class="mb-1"></b-skeleton>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="3" v-for="(item, i) in activites" :key="i">
        <div class="border p-50 mb-1 rounded-lg">
          <h3 class="text-center cursor-pointer mt-1 mb-1" @click="$router.push({
              name: switchType($route.name).route,
              params: { id: item.id, title: item.title, type: switchType($route.name) },
            })
            ">
            {{ item.title }}
          </h3>
          <hr class="my-0" />
          <div class="d-flex justify-content-between">
            <div>
              <b-badge class="mt-50" variant="light-success">{{
                "عدد المقاعد " + item.max_member
              }}</b-badge>
            </div>

            <div>
              <b-badge class="mt-50" :variant="item.max_member == item.number_reservation
                    ? 'light-danger'
                    : 'light-success'
                  ">{{
      item.max_member == item.number_reservation
      ? "مكتمل"
      : "العدد المتبقي" +
      " " +
      (item.max_member - item.number_reservation)
    }}</b-badge>
            </div>


          </div>
        </div>
      </b-col>
    </b-row>
    <div v-if="!loading && activites.length == 0" class="mb-0 w-100 my-2 bg-light-primary rounded-sm shadow-sm">
      <div class="alert-body p-1">
        <feather-icon icon="InfoIcon" size="16" class="mx-50" />
        <span>عذراً لا يوجد نتائج لعرضها</span>
      </div>
      <div class="w-100 rounded-sm alert-dropdown"></div>
    </div>
    <Pagination v-model="currentPage" :total-rows="pagination.total_row" align="center" :per-page="per_page" class="mb-0">
    </Pagination>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BButton,
  BBadge,
  BSkeleton,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import Pagination from "@/components/pagination/index.vue";
import * as ep from "@/modules/manage-app/endpoints";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BButton,
    BBadge,
    Pagination,
    BSkeleton,
  },

  data: (vm) => {
    return {
      currentPage: 1,
      per_page: 10,
      listRoute: [
        {
          CurrentRoute: "bookings-activites",
          name: "Activity",
          ep: "action-appointments",
          route: "bookings-activite",
          title: "حجوزات النشاطات",
          getActivity: () => vm.getActivity({ page: vm.currentPage, ep: ep.ACTIVITE })
        },
        {
          CurrentRoute: "bookings-events",
          name: "Event",
          ep: "action-appointments",
          route: "bookings-event",
          title: "حجوزات الفعاليات",
          getActivity:()=> vm.getActivity({ page: vm.currentPage, ep: ep.EVENT }),
        },
        {
          CurrentRoute: "bookings-interactive-museum",
          name: "interactive_museums",
          ep: "internal-activity-appointments",
          route: "booking-interactive-museum",
          title: "متحف تفاعلي",
          getActivity:()=> vm.getActivity({ page: vm.currentPage, ep: ep.INTERACTIVE_MUSEUM })
        },
        {
          CurrentRoute: "bookings-training-heritage",
          name: "heritage_trainings",
          ep: "internal-activity-appointments",
          route: "booking-training-heritage",
          title: "تدريبات تراثية",
          getActivity:()=> vm.getActivity({ page: vm.currentPage, ep: ep.TRAINING_HERITAGE })
        },
        {
          CurrentRoute: "bookings-awareness-sessions",
          name: "awareness_sessions",
          ep: "internal-activity-appointments",
          route: "booking-awareness-sessions",
          title: "جلسات التوعية",
          getActivity:()=> vm.getActivity({ page: vm.currentPage, ep: ep.AWARENESS_SESSIONS })
        },
      ]
    };
  },

  computed: {
    ...mapGetters("manageCenter/bookings/activites", [
      "activites",
      "loading",
      "pagination",
    ]),
  },

  methods: {
    ...mapActions("manageCenter/bookings/activites", ["getActivity"]),
    switchType(route) {
      switch (route) {
        case "bookings-activites":
          return { name: "Activity", ep: "action-appointments", route: "bookings-activite" };
        case "bookings-events":
          return { name: "Event", ep: "action-appointments", route: "bookings-event" };
        case "bookings-interactive-museum":
          return { name: "interactive_museums", ep: "internal-activity-appointments", route: "booking-interactive-museum" };
        case "bookings-training-heritage":
          return { name: "heritage_trainings", ep: "internal-activity-appointments", route: "booking-training-heritage" };
        case "bookings-awareness-sessions":
          return { name: "awareness_sessions", ep: "internal-activity-appointments", route: "booking-awareness-sessions" };
      }
    },
  },

  watch: {
    "$route.name"() {

      console.log(this.$route.name)
      this.listRoute.find((item)=>item.CurrentRoute == this.$route.name).getActivity();
    },
  },
  created() {
    this.listRoute.find((item)=>item.CurrentRoute == this.$route.name).getActivity();
  }
};
</script>